/** @format */

import axios from 'axios';

export const EatgetherAPI = (options, baseURL) => {
	const authorizationToken =
		typeof window !== 'undefined' && localStorage.getItem('token', null);
	const { RAZZLE_API_URL } = process.env;
	const headers = {
		'Content-Type': 'application/json',
		...options
	};

	if (authorizationToken)
		headers.Authorization = `BEARER ${authorizationToken}`;

	return axios.create({
		baseURL: baseURL || RAZZLE_API_URL,
		headers
	});
};
