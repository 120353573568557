/** @format */
import React from 'react';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import linkifyHtml from 'linkifyjs/html';
// import mention from 'linkifyjs/plugins/mention';
// import hashtag from 'linkifyjs/plugins/hashtag';

export const imageSizeLimit = 1000000; // 1mb
export const payerInfo = {
	goDutch: 0,
	split: 1,
	hostPays: 2,
	userPays: 3
};

export const getAPILanguage = () => {
	const windowGlobal = typeof window !== 'undefined' && window;
	const localizedLang =
		windowGlobal && windowGlobal.localStorage.getItem('i18nextLng', '');
	switch (localizedLang) {
		case 'en':
			return 'en-US';
		case 'en-US':
			return 'en-US';
		case 'zh-TW':
			return 'zh-TW';
		case 'ja-JP':
			return 'ja-JP';
		default:
			return 'zh-TW';
	}
};

export const getLocalStorageLng = () => {
	const history = useHistory();
	const windowGlobal = typeof window !== 'undefined' && window;
	const localizedLang =
		windowGlobal && windowGlobal.localStorage.getItem('i18nextLng');
	switch (localizedLang) {
		case 'en':
			return 'en';
		case 'en-US':
			windowGlobal.localStorage.setItem('i18nextLng', 'en');
			i18n.changeLanguage('en');
			history.push(`/${'en'}/`);
			return 'en';
		case 'zh-TW':
			return 'zh-tw';
		case 'ja-JP':
			return 'ja-JP';
		default:
			i18n.changeLanguage('zh-TW');
			return 'zh-tw';
	}
};

export const getLocalStorageToken = () => {
	const token =
		typeof window !== 'undefined' && localStorage.getItem('token', null);
	return token;
};

export const urlDetection = (text) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, function (url) {
		return '<a class="has-text-link" href="' + url + '">' + url + '</a>';
	});
};

export const linkifyTagToHtml = (content) => {
	if (!content) {
		return '';
	}

	// hashtag(linkifyjs);
	// mention(linkifyjs);
	let htmlContent = content;

	try {
		htmlContent = linkifyHtml(htmlContent, {
			defaultProtocol: 'https'
		});

		htmlContent = htmlContent.replace(/\n/g, '<br>');
	} catch (err) {
		console.log(err);
		htmlContent = content;
	}

	// const tags = linkifyjs.find(htmlContent);
	// tags.map((tag) => {
	// 	if (tag.type === 'mention') {
	// 		htmlContent = htmlContent.replace(
	// 			tag.value,
	// 			`<a href="/profile${tag.href}">${tag.value}</a>`
	// 		);
	// 	} else if (tag.type === 'hashtag') {
	// 		htmlContent = htmlContent.replace(
	// 			tag.value,
	// 			`<a href="/profile/${tag.value.replace('#', '')}">${tag.value}</a>`
	// 		);
	// 	}
	// });

	return htmlContent;
};

export const payerRenderFun = (param) => {
	switch (param) {
		case 0:
			return (
				<span>
					<Trans i18nKey="createMeetup.goDutch"></Trans>
				</span>
			);
		case 1:
			return (
				<span>
					<Trans i18nKey="createMeetup.shareEqual"></Trans>
				</span>
			);
		case 2:
			return (
				<span>
					<Trans i18nKey="createMeetup.treatByhost"></Trans>
				</span>
			);
		case 3:
			return (
				<span>
					<Trans i18nKey="createMeetup.payByhost"></Trans>
				</span>
			);
		default:
			return '';
	}
};

export const convertToTimestamp = (dateString) => {
	return new Date(dateString).getTime() / 1000;
};

export const copyText = (str) => {
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

export const meetupCategories = [
	{
		ID: '16ae3cde-55dc-4242-abd9-c5b0ff42f103',
		name: '商業投資',
		seo: '投資理財'
	},
	{
		ID: '39a23c01-f0f2-487e-bb01-820e3b385694',
		name: '影音展演',
		seo: '看表演'
	},
	{ ID: '55ada64f-fb58-45fd-bea2-4313e5738636', name: '遊戲卡牌', seo: '玩樂' },
	{ ID: '5aeb9d93-89e7-4eda-81e3-6eb3a2e8e21d', name: '美容時尚', seo: '美容' },
	{ ID: '7f27ab93-5bfd-46ee-94b8-033f32f918ec', name: '其他', seo: '參加活動' },
	{
		ID: '941f6c66-2bbe-4c78-ad9d-51e201baea46',
		name: '體驗學習',
		seo: '參加課程'
	},
	{ ID: '9f7086f5-a1c0-42d6-b311-cec43a9c6b66', name: '命理諮商', seo: '諮詢' },
	{ ID: '9fd4c02d-5e24-4fc0-9247-b26d6014a696', name: '美食美酒', seo: '吃飯' },
	{ ID: 'cab2ca48-d8c2-4fe8-91d3-ccb7d1bad0cd', name: '旅行出遊', seo: '旅行' },
	{ ID: 'd409fcb5-f092-4e48-a785-047de2edba67', name: '運動健身', seo: '運動' },
	{ ID: 'e868cfa7-135e-40fa-b580-593c37288032', name: '唱歌派對', seo: 'KTV' }
];

export const getMeetupCategorieSeo = (id) => {
	const result = meetupCategories.find((item) => item.ID === id);
	if (result) {
		return result.seo;
	} else {
		return '';
	}
};
