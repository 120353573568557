/** @format */

export const initialState = {
	id: '',
	loading: false,
	data: {
		ID: '',
		aliasID: '',
		email: '',
		identity: {
			pro: false,
			vip: false,
			gold: false,
			staff: false,
			partner: false,
			official: false
		},
		profile: {
			countryID: '',
			country: '',
			cityID: '01',
			city: '',
			popularity: 0,
			nickname: '',
			gender: 0,
			birthday: '',
			astrologicalSign: '',
			age: 0,
			jobID: '',
			job: '',
			jobTitle: '',
			introduction: '',
			favorMeetupsID: [],
			favorMeetups: [],
			displayIdentity: 'Normal'
		}
	},
	avatar: [''],
	followingStatus: false,
	rating: { amount: 0, one: 0, two: 0, three: 0, four: 0, five: 0 },
	meetups: {
		current: [],
		history: [],
		isReadable: false
	},
	reviews: [],
	friends: [],
	followings: [],
	fans: [],
	amount: {
		friend: 0,
		following: 0,
		fan: 0,
		meetup: {
			amount: 0,
			success: 0
		},
		attend: 0,
		apply: {
			amount: 0,
			success: 0
		}
	}
};
