/** @format */

import { EatgetherAPI } from 'apis/axios';
import { getAPILanguage } from 'utils/utils';
const { RAZZLE_API_URL } = process.env;

export const getUserProfile = async (aud) => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/profile/${aud}?language=${language}`
		);

		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserProfileAvatar = async (aud) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/member/avatar/${aud}`);

		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};
export const changeUserProfileMeetupConfig = async (data) => {
	const body = {
		configurations: data
	};
	const authorizationToken = localStorage.getItem('token', null);
	const url = `${RAZZLE_API_URL}/v4.0/member/config`,
		response = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify(body)
		});

	return response;
};

export const getProfileConfig = async () => {
	try {
		return await EatgetherAPI().get('v4.0/member/config');
	} catch (e) {
		throw e;
	}
};

export const getUserProfileMeetupList = async (aud) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/member/mymeetup/${aud}`);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserProfileAlias = async (aliasID) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/exist/alias-id/${aliasID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserFollowingAmount = async (aud) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/following-amount/${aud}`
		);

		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserFollowingList = async (aud) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/member/following`);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserFansAmount = async (aud) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/follower-amount/${aud}`
		);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserFansList = async (aud) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/member/follower/${aud}`);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserFriendsAmount = async (aud) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/friends-amount/${aud}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserFriendsList = async (aud) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/member/friends`);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserBlockedList = async (aud) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/block/${aud}/page/${1}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserReviewedList = async (profileID) => {
	try {
		const response = await EatgetherAPI().get(
			`v4.0/member/reviews/${profileID}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserRatingScore = async (profileID) => {
	try {
		const response = await EatgetherAPI().get(`v4.0/member/score/${profileID}`);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserMeetupAmount = async (memberID) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/meetup-amount/${memberID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserAttendAmount = async (memberID) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/attend-amount/${memberID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUserApplyAmount = async (memberID) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/apply-amount/${memberID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};
