/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Loadable from 'react-loadable';
import {
	Route,
	__RouterContext,
	withRouter,
	useLocation
} from 'react-router-dom';
import i18n from 'i18n';
import { AuthProvider } from './context/auth';
import { MiscProvider } from './context/misc';
import { ProfileProvider } from './context/profile';
import { MeetupProvider } from './context/meetup';
import { getAPILanguage, getLocalStorageLng } from 'utils/utils';
import { useSSR } from 'react-i18next';
import { useSystem } from './modules/navbar';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/App.scss';
import './styles/animation.scss';

const ScrollToTop = Loadable({
	loader: () => import('./ScrollToTop'),
	loading: () => null
});

const Navbar = Loadable({
	loader: () => import('./components/Navbar/'),
	loading: () => null
});

const Footer = Loadable({
	loader: () => import('./components/Footer/'),
	loading: () => null
});

const MainRoute = Loadable({
	loader: () => import('./routes/MainRoute'),
	loading: () => null
});

const Deeplink = Loadable({
	loader: () => import('../src/Deeplink'),
	loading: () => null
});

const App = withRouter(() => {
	if (typeof window !== 'undefined') {
		useSSR(window.initialI18nStore, window.initialLanguage);
		const { boundFetchForbiddenTextList } = useSystem();
		useEffect(() => {
			if (window.__PRELOADED_FIREBASE_CONFIG__) {
				const config = JSON.parse(window.__PRELOADED_FIREBASE_CONFIG__);
				boundFetchForbiddenTextList(config);
			}
		}, [window.__PRELOADED_FIREBASE_CONFIG__]);
	}
	const localizedLang = getLocalStorageLng();
	const location = useLocation();
	const baseUrl = `/${i18n.language}`.toLowerCase();
	const navbarHiddenDetection =
		location.pathname === '/' ||
		location.pathname === `/${localizedLang}` ||
		location.pathname === `/${localizedLang}/` ||
		location.pathname.includes('/post/') ||
		location.pathname.includes('profile') ||
		location.pathname.includes('redeem') ||
		location.pathname.includes('iap') ||
		(location.pathname.includes('meetup') &&
			!location.pathname.includes('meetup/create') &&
			!location.pathname.includes('meetup/update'));
	return (
		<div style={{}}>
			<AuthProvider>
				<ProfileProvider>
					<MeetupProvider>
						<MiscProvider>
							<div style={{ zIndex: 99, width: `${100}%` }}>
								{navbarHiddenDetection && <Deeplink />}
								<Navbar />
							</div>
							<ScrollToTop />
							<div style={{ minHeight: '90vh' }}>
								<Route
									render={({ location }) => {
										return (
											<TransitionGroup>
												<Route
													location={location}
													render={() => <MainRoute />}
												/>
											</TransitionGroup>
										);
									}}
								/>
							</div>
							<Footer />
						</MiscProvider>
					</MeetupProvider>
				</ProfileProvider>
			</AuthProvider>
		</div>
	);
});

export default App;
