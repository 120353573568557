/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[`${types.CHANGE_CREATE_MEETUP_STATUS}`]: (state, action) => ({
			...state,
			createMeetupStatus: action.payload.status
		}),
		[`${types.FETCH_MEETUP_DETAIL}_FULFILLED`]: (state, action) => ({
			...state,
			data: action.payload.meetup
		})
	},
	initialState
);
