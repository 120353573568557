/** @format */

import { EatgetherAPI } from 'apis/axios';
import { getAPILanguage } from 'utils/utils';

const { RAZZLE_DOMAIN_URL } = process.env;

export const getCountries = async () => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.0/location/country/?language=${language}`
		);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getMemberJobList = async () => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/job?language=${language}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getMeetupCategories = async () => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.0/meetup/category?language=${language}`
		);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getCityByCountryCode = async (countryId) => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.0/location/country/${countryId}/city?language=${language}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserIPInfo = async () => {
	const myHeaders = new Headers();
	myHeaders.append('pragma', 'no-cache');
	myHeaders.append('cache-control', 'no-cache');

	const myInit = {
		method: 'GET',
		headers: myHeaders
	};
	try {
		return fetch('https://extreme-ip-lookup.com/json/', myInit)
			.then((res) => res.json())
			.then((response) => {
				return response;
			});
	} catch (e) {
		return e.response;
	}
};

// fetch og tag by node.js
export const getHtmlTag = async (url) => {
	return fetch(`${RAZZLE_DOMAIN_URL}/ogtag?t=${encodeURIComponent(url)}`)
		.then((res) => {
			return res.json();
		})
		.catch((err) => {
			console.log(err);
			return {};
		});
};

export const getFilterKeyWord = async (countryId) => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().get(
			`/v4.1/meetup/filterKeyWork/${language}`
		);

		return response;
	} catch (e) {
		return e.response;
	}
};
