/** @format */

export const types = {
	SET_LOADING: 'SET_LOADING',
	SET_MEMBER_ID: 'SET_MEMBER_ID',
	FETCH_PROFILE: 'FETCH_PROFILE',
	FETCH_PROFILE_AVATAR: 'FETCH_PROFILE_AVATAR',
	FETCH_FOLLOWING_STATUS: 'FETCH_FOLLOWING_STATUS',
	FOLLOW_MEMBER: 'FOLLOW_MEMBER',
	UNFOLLOW_MEMBER: 'UNFOLLOW_MEMBER',
	FETCH_RATING_SCORE: 'FETCH_RATING_SCORE',
	FETCH_REVIEW: 'FETCH_REVIEW',
	FETCH_FRIEND_AMOUNT: 'FETCH_FRIEND_AMOUNT',
	FETCH_FRIEND: 'FETCH_FRIEND',
	FETCH_FANS_AMOUNT: 'FETCH_FANS_AMOUNT',
	FETCH_FANS: 'FETCH_FANS',
	FETCH_FOLLOWING_AMOUNT: 'FETCH_FOLLOWING_AMOUNT',
	FETCH_FOLLOWING: 'FETCH_FOLLOWING',
	FETCH_MEETUP_AMOUNT: 'FETCH_MEETUP_AMOUNT',
	FETCH_ATTEND_AMOUNT: 'FETCH_ATTEND_AMOUNT',
	FETCH_APPLY_AMOUNT: 'FETCH_APPLY_AMOUNT',
	FETCH_MEETUP: 'FETCH_MEETUP',
	FETCH_MEETUP_CONFIG: 'FETCH_MEETUP_CONFIG',
	UPDATE_MEETUP_CONFIG: 'UPDATE_MEETUP_CONFIG',
	UPDATE_ALIAS_ID: 'UPDATE_ALIAS_ID',
	CHECK_ALIAS_EXIST: 'CHECK_ALIAS_EXIST',
	CLEAR_PROFILE: 'CLEAR_PROFILE'
};

export default types;
