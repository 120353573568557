/** @format */

import { EatgetherAPI } from 'apis/axios';
import { getAPILanguage } from 'utils/utils';

export const registerUser = async (
	phoneCode,
	phoneNumber,
	email,
	referenceCode
) => {
	try {
		const body = {
			phoneCode,
			phoneNumber,
			email,
			...(referenceCode && { referenceCode })
		};
		const response = await EatgetherAPI().post('/v4.0/member/register', body);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getNewRefreshToken = async () => {
	const refreshToken = localStorage.getItem('refreshToken');

	try {
		const response = await EatgetherAPI({
			Authorization: `BEARER ${refreshToken}`
		}).get('/v4.0/member/token/refresh');

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getRegisterStatusByToken = async () => {
	try {
		const response = await EatgetherAPI().get('/v4.0/member/register/status');

		return response;
	} catch (e) {
		return e.response;
	}
};

export const authorizeUser = async (phoneCode, phoneNumber, verifyCode) => {
	try {
		const response = await EatgetherAPI().post('/v4.0/member/authorize', {
			phoneCode,
			phoneNumber,
			verifyCode
		});

		return response;
	} catch (e) {
		return e.response;
	}
};

export const authenticateUser = async (phoneCode, phoneNumber) => {
	const language = getAPILanguage();

	try {
		const response = await EatgetherAPI().post('/v4.0/member/authenticate', {
			phoneCode,
			phoneNumber,
			language
		});

		return response;
	} catch (e) {
		return e.response;
	}
};

export const getRecommendFollowList = async () => {
	const language = getAPILanguage();
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/member/recommended?language=${language}`
		);
		return response;
	} catch (e) {
		return e.response;
	}
};
