/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[`${types.ADD_LOADING_REQUEST}`]: (state, action) => ({
			...state,
			loading: action.payload.loading
		}),
		[`${types.REMOVE_LOADING_REQUEST}`]: (state, action) => ({
			...state,
			loading: action.payload.loading
		}),
		[`${types.SET_TITLE}`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				title: action.payload.title
			}
		}),
		[`${types.SET_SELECTED_COUNTRY}`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				selectedCountry: action.payload.selectedCountry,
				selectedCities: []
			}
		}),
		[`${types.FETCH_FILTER_KEYWORD}_FULFILLED`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				searchTitles: action.payload.tags
			}
		}),
		[`${types.SET_SELECTED_CITY}`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				selectedCities: action.payload.selectedCities
			}
		}),
		[`${types.FETCH_COUNTRIES}_FULFILLED`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				countries: action.payload.countries,
				selectedCountry: action.payload.countries[0]
					? action.payload.countries[0]
					: 'TW'
			}
		}),
		[`${types.FETCH_CITIES}_FULFILLED`]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				cities: action.payload.cities
			}
		}),
		[`${types.FETCH_MEETUPS}_FULFILLED`]: (state, action) => ({
			...state,
			meetups: action.payload.meetups
		})
	},
	initialState
);
