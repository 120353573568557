/** @format */

export const types = {
	INIT_POST: 'INIT_POST',
	INIT_DETAIL: 'INIT_DETAIL',
	INIT_COMMENT: 'INIT_COMMENT',
	SET_COMMENT_MESSAGE: 'SET_COMMENT_MESSAGE',
	SET_COMMENT_STATUS: 'SET_COMMENT_STATUS',
	FETCH_POST: 'FETCH_POST',
	FETCH_DETAIL: 'FETCH_DETAIL',
	SET_POST_ID: 'SET_POST_ID',
	SET_COMMENT_ID: 'SET_COMMENT_ID',
	FETCH_COMMENT: 'FETCH_COMMENT',
	CREATE_COMMENT: 'CREATE_COMMENT',
	UPDATE_COMMENT: 'UPDATE_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SWITCH_LOADING: 'SWITCH_LOADING',
	SWITCH_CATEGORY: 'SWITCH_CATEGORY',
	TOGGLE_COLLECT: 'TOGGLE_COLLECT',
	ADD_COLLECT: 'ADD_COLLECT',
	REMOVE_COLLECT: 'REMOVE_COLLECT'
};

export default types;
