/** @format */

export const initialState = {
	loading: false,
	filter: {
		title: '',
		countries: [
			{ ID: 'TW', name: '台灣' },
			{ ID: 'HK', name: '香港/澳門' },
			{ ID: 'JP', name: '日本' }
		],
		cities: {},
		selectedCountry: { ID: 'TW', name: '台灣' },
		selectedCities: [],
		selectedCategories: [],
		language: '',
		searchTitles: []
	},
	meetups: []
};
