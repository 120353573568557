/** @format */

export const registrationStatus = {
	// New registration
	NEW_USER: 0,
	// Unfinished registration
	UNFINISH: 1,
	// Logged in
	REGISTERED: 2
};

const types = {
	ADD_SYSTEM_REQUEST: 'ADD_SYSTEM_REQUEST',
	REMOVE_SYSTEM_REQUEST: 'REMOVE_SYSTEM_REQUEST',
	FETCH_LANGUAGE: 'FETCH_LANGUAGE',
	FETCH_FORBBIDEN_TEXT_LIST: 'FETCH_FORBBIDEN_TEXT_LIST',
	GET_TOKEN: 'GET_TOKEN',
	REMOVE_TOKEN: 'REMOVE_TOKEN',
	CHECK_USER_CREATE_MEETUP_STATUS: 'CHECK_USER_CREATE_MEETUP_STATUS',
	CHECK_CREATE_MEETUP_STATUS: 'CHECK_CREATE_MEETUP_STATUS',
	FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
	FETCH_USER_PROFILE_AVATAR: 'FETCH_USER_PROFILE_AVATAR',
	FETCH_USER_FRIEND_AMOUNT: 'FETCH_USER_FRIEND_AMOUNT',
	FETCH_USER_FANS_AMOUNT: 'FETCH_USER_FANS_AMOUNT',
	FETCH_USER_FOLLOWING_AMOUNT: 'FETCH_USER_FOLLOWING_AMOUNT',
	FETCH_USER_MEETUP_AMOUNT: 'FETCH_USER_MEETUP_AMOUNT',
	FETCH_USER_ATTEND_AMOUNT: 'FETCH_USER_ATTEND_AMOUNT',
	FETCH_USER_APPLY_AMOUNT: 'FETCH_USER_APPLY_AMOUNT',
	CLEAR_USER_DATA: 'CLEAR_USER_DATA',
	RESET_CREATE_MEETUP_STATUS: 'RESET_CREATE_MEETUP_STATUS'
};

export default types;
