/** @format */

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import './i18n';
import Loadable from 'react-loadable';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { configureStore } from './modules';

window.main = () => {
	render(App);
};

if (module.hot) {
	module.hot.accept('./App', () => {
		const NewApp = require('./App').default;
		render(NewApp);
	});
}

function render(Root) {
	const preloadState = {
		...window.__PRELOADED_STATE__
	};
	const store = configureStore(preloadState);
	const root = document.getElementById('root');
	Loadable.preloadReady().then(() => {
		hydrate(
			<Provider store={store}>
				<HelmetProvider>
					<BrowserRouter>
						<Root />
					</BrowserRouter>
				</HelmetProvider>
			</Provider>,
			root
		);
	});
}

// const BaseApp = () => {
//   useSSR(window.initialI18nStore, window.initialLanguage);
//   return (
//     <Suspense fallback={<div></div>}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Suspense>
//   );
// };

// hydrate(<BaseApp />, document.getElementById("root"));

// if (module.hot) {
//   module.hot.accept();
// }
