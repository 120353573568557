/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[`${types.TOGGLE_USE_APP_MODAL}`]: (state, action) => ({
			...state,
			useAppModal: action.payload.isOpen
		}),
		[`${types.TOGGLE_EXTRA_MEETUP_MODAL}`]: (state, action) => ({
			...state,
			extraMeetupModal: action.payload.isOpen
		}),
		[`${types.TOGGLE_PROFILE_MODIFY_MODAL}`]: (state, action) => ({
			...state,
			profileModifyModal: action.payload.isOpen
		}),
		[`${types.TOGGLE_RIGHT_SIDEBAR_MODAL}`]: (state, action) => ({
			...state,
			rightSidebar: action.payload.isOpen
		}),
		[`${types.TOGGLE_ALERT_MODAL}`]: (state, action) => ({
			...state,
			alertModal: {
				isOpen: action.payload.isOpen,
				type: action.payload.type
			}
		}),
		[`${types.TOGGLE_SHARE_MODAL}`]: (state, action) => ({
			...state,
			shareModal: {
				isOpen: action.payload.isOpen,
				copy: action.payload.copy
			}
		})
	},
	initialState
);
