/** @format */

export const initialState = {
	loading: false,
	warningStatus: 'newest',
	filter: {
		totalPage: 5,
		page: 1,
		limit: 10,
		isEnabled: true,
		category: 'newest'
	},
	list: [],
	detail: {
		ID: '',
		additional: {
			hyperLink: '',
			imageURL: '',
			meetupID: '',
			voiceURL: ''
		},
		content: '',
		createdAt: '',
		isEnable: true,
		isLocked: true,
		meetup: {
			ID: '',
			address: '',
			categoriesID: '',
			categoriesName: '',
			cityName: '',
			cover: '',
			member: {
				ID: '',
				age: 0,
				aliasID: '',
				cityID: '',
				cover: '',
				displayIdentity: '',
				gender: 1,
				identity: {
					gold: true,
					official: true,
					partner: true,
					pro: true,
					staff: true,
					vip: true
				},
				job: '',
				jobID: '',
				nickName: '',
				popularity: 0
			},
			reward: 0,
			startOn: '',
			title: '',
			utcOffset: 0
		},
		member: {
			ID: '',
			age: 0,
			aliasID: '',
			cityID: '',
			cover: '',
			displayIdentity: '',
			gender: 0,
			identity: {
				gold: true,
				official: true,
				partner: true,
				pro: true,
				staff: true,
				vip: true
			},
			job: '',
			jobID: '',
			nickName: '',
			popularity: 0
		},
		title: '',
		unlockPrice: 0
	},
	comment: {
		id: '',
		message: '',
		status: 'none',
		filter: {
			totalPage: 5,
			page: 1
		},
		list: []
	}
};
