/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[types.INIT_POST]: (state, action) => ({
			...state,
			list: []
		}),
		[types.INIT_DETAIL]: (state, action) => ({
			...state,
			detail: initialState.detail
		}),
		[types.INIT_COMMENT]: (state, action) => ({
			...state,
			comment: initialState.comment
		}),
		[types.SET_COMMENT_MESSAGE]: (state, action) => ({
			...state,
			comment: {
				...initialState.comment,
				message: action.payload.message
			}
		}),
		[types.SET_COMMENT_STATUS]: (state, action) => ({
			...state,
			comment: {
				...initialState.comment,
				status: action.payload.status
			}
		}),
		[`${types.FETCH_POST}_FULFILLED`]: (state, action) => ({
			...state,
			list: [...state.list, ...action.payload.posts],
			filter: {
				...state.filter,
				totalPage: action.payload.totalPage,
				page: action.payload.page,
				limit: action.payload.limit,
				isEnable: action.payload.isEnable,
				memberId: action.payload.memberId
			}
		}),
		[`${types.FETCH_POST}_REJECTED`]: (state, action) => ({
			...state,
			warningStatus: 'error',
			loading: false
		}),
		[`${types.FETCH_DETAIL}_FULFILLED`]: (state, action) => ({
			...state,
			detail: action.payload.data
		}),
		[`${types.FETCH_DETAIL}_REJECTED`]: (state, action) => ({
			...state,
			detail: {
				...state.detail
			}
		}),
		[`${types.SET_POST_ID}`]: (state, action) => ({
			...state,
			detail: {
				...state.detail,
				ID: action.payload.id
			}
		}),
		[`${types.SET_COMMENT_ID}`]: (state, action) => ({
			...state,
			comment: {
				...state.comment,
				id: action.payload.id
			}
		}),
		[`${types.FETCH_COMMENT}_FULFILLED`]: (state, action) => ({
			...state,
			id: action.payload.id,
			comment: {
				list: action.payload.list,
				filter: {
					totalPage: action.payload.totalPage,
					page: action.payload.page,
					limit: action.payload.limit
				}
			}
		}),
		[`${types.UPDATE_COMMENT}_FULFILLED`]: (state, action) => ({
			...state,
			comment: {
				...state.comment,
				id: ''
			}
		}),
		[types.SWITCH_LOADING]: (state, action) => ({
			...state,
			loading: action.payload.loading
		}),
		[types.SWITCH_CATEGORY]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				category: action.payload.category
			},
			warningStatus: action.payload.category
		}),
		[types.TOGGLE_COLLECT]: (state, action) => ({
			...state,
			detail: {
				...state.detail,
				isCollect: action.payload.isCollect
			}
		})
	},
	initialState
);
