/** @format */

export const initialState = {
	request: 0,
	forbiddenTextList: [
		'ff8015',
		'dy394',
		'shan689',
		'dy394',
		'tt92888',
		'lly4',
		'tt92888',
		'LL0888',
		'jiny678',
		'ty2668',
		'x8809',
		'A9085',
		'nuo52',
		'baby76899',
		'wrppkk',
		'qew899',
		'alina',
		'56154050',
		'chen666668',
		'jwkj7389',
		'clara83948',
		'anna777888999'
	],
	token: {
		aud: '',
		exp: 0,
		iat: 0,
		iss: '',
		nbf: 0,
		sub: ''
	},
	status: {
		registerStatus: 'none',
		createMeetupStatus: 'none'
	},
	data: {
		ID: '',
		aliasID: '',
		email: '',
		identity: {
			pro: false,
			vip: false,
			gold: false,
			staff: false,
			partner: false,
			official: false
		},
		profile: {
			countryID: '',
			country: '',
			cityID: '01',
			city: '',
			popularity: 0,
			nickname: '',
			gender: 0,
			birthday: '',
			astrologicalSign: '',
			age: 0,
			jobID: '',
			job: '',
			jobTitle: '',
			introduction: '',
			favorMeetupsID: [],
			favorMeetups: [],
			displayIdentity: 'Normal'
		}
	},
	avatar: [''],
	amount: {
		friend: 0,
		following: 0,
		fan: 0,
		meetup: {
			amount: 0,
			success: 0
		},
		attend: 0,
		apply: {
			amount: 0,
			success: 0
		}
	}
};
