/** @format */

import React, { useState, useEffect, useMemo } from 'react';
import { createContext } from 'react';
import {
	getCountries,
	getMemberJobList,
	getMeetupCategories,
	getCityByCountryCode
} from 'apis/misc';
import { getAPILanguage } from 'utils/utils';

export const useAuth = () => ({});

export const MiscContext = createContext({});

export const MiscProvider = (props) => {
	const [cities, setCities] = useState([]);
	const [countries, setCountries] = useState([]);
	const [jobCategories, setJobCategories] = useState([]);
	const [meetupCategories, setMeetupCategories] = useState([]);

	const getCountryList = useMemo(
		() => async () => {
			try {
				const { data } = await getCountries();
				setCountries(data.countries);
				return data.countries;
			} catch (e) {
				console.log('e: ', e);
			}
		},
		[countries]
	);

	const getJobList = useMemo(
		() => async () => {
			try {
				const { data } = await getMemberJobList();
				setJobCategories(data && data.jobs);
				return data;
			} catch (e) {
				console.log('e: ', e);
			}
		},
		[jobCategories]
	);

	const getCategory = useMemo(
		() => async () => {
			try {
				const { data } = await getMeetupCategories();
				setMeetupCategories(data.meetupCategories);
				return data;
			} catch (e) {
				console.log('e: ', e);
			}
		},
		[meetupCategories]
	);

	const getCities = useMemo(
		() => async (countryId) => {
			try {
				const { data } = await getCityByCountryCode(countryId);
				setCities(data.cities);
				return data.cities;
			} catch (e) {
				console.log('e: ', e);
			}
		},
		[cities]
	);

	useEffect(() => {
		getCountryList();
		getJobList();
		getCategory();
	}, [getAPILanguage()]);

	return (
		<MiscContext.Provider
			value={{
				getCities,
				cities,
				countries,
				jobCategories,
				meetupCategories
			}}>
			<>{props.children}</>
		</MiscContext.Provider>
	);
};
