/** @format */

import { createAction } from 'redux-actions';
import { getRegisterStatusByToken } from '../../apis/auth';
import { getUserCreateMeetupStatus } from '../../apis/meetup';
import { getAPILanguage } from '../../utils/utils';
import types from './types';
import { initialState } from './state';
import jwtDecode from 'jwt-decode';

import {
	getUserProfile,
	getUserProfileAvatar,
	getUserFriendsAmount,
	getUserFansAmount,
	getUserFollowingAmount,
	getUserMeetupAmount,
	getUserAttendAmount,
	getUserApplyAmount
} from 'apis/profile';

export const addSystemRequest = createAction(
	types.ADD_SYSTEM_REQUEST,
	() => (dispatch, getState) => {
		const { request } = getState().navbar;
		return { request: request + 1 };
	}
);
export const removeSystemRequest = createAction(
	types.REMOVE_SYSTEM_REQUEST,
	() => (dispatch, getState) => {
		const { request } = getState().navbar;
		return { request: request - 1 };
	}
);

export const fetchLanguage = createAction([types.FETCH_LANGUAGE], () => {
	try {
		const language = getAPILanguage();
		return { language };
	} catch (e) {
		console.log('e: ', e);
		return { language: 'zh-tw' };
	}
});

export const fetchForbiddenTextList = createAction(
	[types.FETCH_FORBBIDEN_TEXT_LIST],
	(list) => {
		return { list };
	}
);

export const getToken = createAction(types.GET_TOKEN, () => {
	const token = localStorage.getItem('token', null);
	if (token) {
		return { token: jwtDecode(token) };
	} else {
		return { token: initialState.token };
	}
});

export const removeToken = createAction(types.REMOVE_TOKEN, () => ({}));

export const checkCreateMeetupStatus = createAction(
	types.CHECK_CREATE_MEETUP_STATUS,
	() => async (dispatch, getState) => {
		let result = {
			registerStatus: -1,
			createMeetupStatus: 'fail'
		};
		dispatch(addSystemRequest());
		try {
			const registerStatus = await getRegisterStatusByToken();
			if (registerStatus.status !== 401) {
				result.registerStatus = registerStatus.data.status;
			}
		} catch (e) {
			console.log('getRegisterStatusByToken', e);
		}
		try {
			const createMeetupStatus = await getUserCreateMeetupStatus();
			if (createMeetupStatus.data.extraCreate) {
				result.createMeetupStatus = 'extra';
			} else {
				result.createMeetupStatus = 'success';
			}
		} catch (e) {
			console.log('getUserCreateMeetupStatus', e);
		}
		dispatch(removeSystemRequest());
		return { status: result };
	}
);

export const fetchProfile = createAction(
	types.FETCH_USER_PROFILE,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserProfile(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { member: response.data.member };
	}
);

export const fetchProfileAvatar = createAction(
	types.FETCH_USER_PROFILE_AVATAR,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserProfileAvatar(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { avatars: response.data.avatars };
	}
);

export const fetchMeetupAmount = createAction(
	types.FETCH_USER_MEETUP_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserMeetupAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return {
			amount: response.data.amount,
			success: response.data.successAmount
		};
	}
);

export const fetchAttendAmount = createAction(
	types.FETCH_USER_ATTEND_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserAttendAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { amount: response.data.amount };
	}
);

export const fetchApplyAmount = createAction(
	types.FETCH_USER_APPLY_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserApplyAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return {
			amount: response.data.amount,
			success: response.data.successAmount
		};
	}
);

export const fetchFriendAmount = createAction(
	types.FETCH_USER_FRIEND_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserFriendsAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { friendAmount: response.data.friends };
	}
);

export const fetchFansAmount = createAction(
	types.FETCH_USER_FANS_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserFansAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { fansAmount: response.data.follower };
	}
);

export const fetchFollowingAmount = createAction(
	types.FETCH_USER_FOLLOWING_AMOUNT,
	() => async (dispatch, useState) => {
		const { navbar } = useState();

		dispatch(addSystemRequest());
		const response = await getUserFollowingAmount(navbar.token.aud);
		dispatch(removeSystemRequest());
		return { followingAmount: response.data.following };
	}
);

export const clearUserData = createAction(types.CLEAR_USER_DATA, () => ({}));

export const resetCreateMeetupStatus = createAction(
	types.RESET_CREATE_MEETUP_STATUS,
	() => ({})
);
