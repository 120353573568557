/** @format */

export const initialState = {
	id: '',
	loading: false,
	data: {
		ID: '',
		publishingLocations: [
			{
				countryID: '',
				countryName: '',
				cityID: '',
				cityName: ''
			}
		],
		categoryID: '',
		categoryName: '',
		covers: [''],
		title: '',
		startOn: '',
		content: '',
		reward: 0,
		payer: 0,
		budget: 0,
		numberOfPeople: 0,
		lastConfirmedAt: '',
		isCutoff: false,
		status: 1,
		promotionAmount: 0,
		commentAmount: 0,
		place: {
			ID: '',
			name: '',
			city: '',
			address: '',
			phoneNumber: '',
			offset: 480
		},
		host: {
			ID: '',
			avatar: '',
			nickname: '',
			age: 1,
			city: '',
			job: '',
			popularity: 0,
			identities: {
				pro: false,
				vip: false,
				gold: false,
				staff: false,
				partner: false,
				official: false
			},
			displayIdentity: 'Normal'
		}
	},
	createMeetupStatus: 'none'
};
