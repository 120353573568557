/** @format */

import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';
import thunkFSAMiddleware from 'redux-thunk-fsa';
import promiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

export const configureStore = (preloadState) => {
	const middlewares = [thunkFSAMiddleware, promiseMiddleware];
	const store = createStore(
		reducers,
		{
			...preloadState
		},
		composeWithDevTools(applyMiddleware(...middlewares))
	);

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const nextReducers = require('./reducers').default; // eslint-disable-line global-require
			store.replaceReducer(nextReducers);
		});
	}

	return store;
};
