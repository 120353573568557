/** @format */

import axios from 'axios';
import { EatgetherAPI } from 'apis/axios';
import { getAPILanguage } from 'utils/utils';

const { RAZZLE_API_URL } = process.env;

export const getUserMeetupPlace = async (val) => {
	const language = getAPILanguage();
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/location/query/${val}?language=${language}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const updateUserMeetup = async (body, meetupID) => {
	const authorizationToken = localStorage.getItem('token', null);
	const url = `${RAZZLE_API_URL}/v4.0/meetup/update/${meetupID}`,
		response = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify(body)
		});

	return response;
};

export const applyUserMeetup = async (body, meetupID) => {
	try {
		const response = await EatgetherAPI().post(
			`/v4.0/meetup/apply/${meetupID}`,
			body
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getApplyUserMeetupStatus = async (meetupID) => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/meetup/apply/${meetupID}`);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getExtraApplyUserMeetupStatus = async () => {
	try {
		const response = await EatgetherAPI().get(`/v4.0/meetup/applies`);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const updateUserMeetupPublishLocation = async (body, meetupID) => {
	const authorizationToken = localStorage.getItem('token', null);
	const url = `${RAZZLE_API_URL}/v4.0/meetup/publishing-location/${meetupID}`,
		response = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify(body)
		});

	return response;
};

export const createUserMeetup = async (body, placeID) => {
	try {
		const response = await EatgetherAPI().post(
			`/v4.0/meetup/create/${placeID}`,
			body
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const deleteUserMeetup = async (meetupID) => {
	try {
		const response = await EatgetherAPI().delete(
			`/v4.0/meetup/delete/${meetupID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const deleteUserMeetupCover = async (meetupID, files) => {
	try {
		const response = await EatgetherAPI().post(
			`/v4.0/meetup/delete/cover/${meetupID}`,
			files
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const createUserMeetupPublishLocation = async (body, meetupID) => {
	try {
		const response = await EatgetherAPI().post(
			`/v4.0/meetup/publishing-location/${meetupID}`,
			body
		);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const createUserMeetupCover = async (files, meetupID) => {
	try {
		const response = await EatgetherAPI().post(
			`/v4.0/meetup/cover/${meetupID}`,
			files
		);

		const fileName = response.data.covers[0];

		const authorizationToken = localStorage.getItem('token', null);
		const url = `${RAZZLE_API_URL}/v4.0/meetup/cover/${meetupID}/${fileName}`;

		const setCoverResponse = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer'
		});
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getAllMeetupListByCity = async (filterOptions, countryID) => {
	try {
		const response = await EatgetherAPI().get(
			`/v4.0/meetup/list/${countryID}`,
			{
				params: filterOptions
			}
		);
		return response;
	} catch (e) {
		return e.response;
	}
};

export const getUserCreateMeetupStatus = async () => {
	try {
		const response = await EatgetherAPI().get(`v4.0/meetup/meetups`);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupCover = async (meetupID) => {
	try {
		const response = await EatgetherAPI().get(`v4.0/meetup/cover/${meetupID}`);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupDetail = async (meetupID) => {
	const language = getAPILanguage();
	try {
		const response = await EatgetherAPI().get(
			`v4.0/meetup/view/${meetupID}?language=${language}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupReviews = async (meetupID) => {
	try {
		const response = await EatgetherAPI().get(
			`v4.0/meetup/reviews/${meetupID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupApplicants = async (meetupID) => {
	try {
		const response = await EatgetherAPI().get(
			`v4.0/meetup/applicants/${meetupID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupParticipants = async (meetupID) => {
	try {
		const response = await EatgetherAPI().get(
			`v4.0/meetup/participants/${meetupID}`
		);
		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const getUniqueMeetupComments = async (meetupID) => {
	const token = localStorage.getItem('token', null);
	try {
		const response = await EatgetherAPI({
			Authorization: `BEARER ${token}`
		}).get(`v4.0/meetup/comment/${meetupID}`);

		return response;
	} catch (e) {
		console.log('e: ', e);
		return e.response;
	}
};

export const createUniqueMeetupComment = async (meetupID, message) => {
	const token = localStorage.getItem('token', null);
	try {
		const response = await EatgetherAPI({
			Authorization: `BEARER ${token}`
		}).post(`/v4.0/meetup/comment/${meetupID}`, {
			message: message
		});

		return response;
	} catch (e) {
		return e.response;
	}
};

export const editUniqueMeetupComment = async (commentID, message) => {
	const authorizationToken = localStorage.getItem('token', null);
	const url = `${RAZZLE_API_URL}/v4.0/meetup/comment/${commentID}`;

	try {
		const response = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			body: JSON.stringify({ message }),
			redirect: 'follow',
			referrerPolicy: 'no-referrer'
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export const deleteUniqueMeetupComment = async (commentID) => {
	const token = localStorage.getItem('token', null);
	try {
		const response = await EatgetherAPI({
			Authorization: `BEARER ${token}`
		}).delete(`/v4.0/meetup/comment/${commentID}`);

		return response;
	} catch (e) {
		return e.response;
	}
};

export const hideUniqueMeetupComment = async (commentID) => {
	const authorizationToken = localStorage.getItem('token', null);
	const url = `${RAZZLE_API_URL}/v4.0/meetup/comment/${commentID}/hide`,
		response = await fetch(url, {
			method: 'PATCH',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `BEARER ${authorizationToken}`
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer'
		});

	return response;
};
