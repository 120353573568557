/** @format */

import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';
import {
	getUserProfile,
	getUserProfileAvatar,
	getUserFollowingAmount,
	getUserFansAmount,
	getUserFriendsAmount,
	getUserFriendsList,
	getUserFansList,
	getUserFollowingList,
	getProfileConfig,
	getUserBlockedList,
	getUserReviewedList,
	getUserRatingScore,
	getUserProfileMeetupList,
	changeUserProfileMeetupConfig,
	getUserMeetupAmount,
	getUserAttendAmount,
	getUserApplyAmount
} from 'apis/profile';

export const ProfileContext = createContext({});

export const ProfileProvider = (props) => {
	const [loading, setLoading] = useState(false);
	const [userId, setuserId] = useState('');
	const [userPoints, setuserPoints] = useState(0); // wine glass

	const getProfile = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserProfile(aud);
			return response;
		} catch (e) {
			return e.response;
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const changeProfileMeetupConfig = async (data) => {
		setLoading(true);
		try {
			const response = await changeUserProfileMeetupConfig(data);
			return response.status;
		} catch (e) {
			return e.response;
		} finally {
			setLoading(false);
		}
	};

	const getReviewedList = async (aud) => {
		setLoading(true);
		try {
			const response = await getUserReviewedList(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getProfileMeetupList = async (aud) => {
		try {
			const response = await getUserProfileMeetupList(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getUserProfileConfig = async () => {
		try {
			const { data } = await getProfileConfig();
			return data.configurations;
		} catch (error) {
			console.log('error:', error);
		}
	};

	const getRatingScore = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserRatingScore(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getProfileAvatar = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserProfileAvatar(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFollowingAmount = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFollowingAmount(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFollowingList = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFollowingList(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFansAmount = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFansAmount(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFansList = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFansList(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFriendsAmount = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFriendsAmount(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getFriendsList = async (aud) => {
		setLoading(true);

		try {
			const response = await getUserFriendsList(aud);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getBlockedList = async (profileID) => {
		setLoading(true);
		try {
			const response = await getUserBlockedList(profileID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getMeetupAmount = async (memberID) => {
		try {
			const response = await getUserMeetupAmount(memberID);
			return response;
		} catch (e) {
			console.log('e: ', e);
			return e.response;
		}
	};

	const getAttendAmount = async (memberID) => {
		try {
			const response = await getUserAttendAmount(memberID);
			return response;
		} catch (e) {
			console.log('e: ', e);
			return e.response;
		}
	};

	const getApplyAmount = async (memberID) => {
		try {
			const response = await getUserApplyAmount(memberID);
			return response;
		} catch (e) {
			console.log('e: ', e);
			return e.response;
		}
	};

	return (
		<ProfileContext.Provider
			value={{
				getProfile,
				getProfileAvatar,
				getFollowingAmount,
				getFriendsAmount,
				getFansAmount,
				getFriendsList,
				getFansList,
				getFollowingList,
				getBlockedList,
				getUserProfileConfig,
				getReviewedList,
				getRatingScore,
				getProfileMeetupList,
				changeProfileMeetupConfig,
				getMeetupAmount,
				getAttendAmount,
				getApplyAmount,
				userId,
				setuserId,
				userPoints,
				setuserPoints
			}}>
			<>{props.children}</>
		</ProfileContext.Provider>
	);
};
