/** @format */
import React, { useState, useReducer } from 'react';
import { createContext } from 'react';
import {
	getUserMeetupPlace,
	createUserMeetup,
	createUserMeetupPublishLocation,
	createUserMeetupCover,
	getAllMeetupListByCity,
	getUserCreateMeetupStatus,
	getUniqueMeetupCover,
	getUniqueMeetupComments,
	editUniqueMeetupComment,
	createUniqueMeetupComment,
	deleteUniqueMeetupComment,
	hideUniqueMeetupComment,
	updateUserMeetup,
	getUniqueMeetupDetail,
	getUniqueMeetupApplicants,
	getUniqueMeetupParticipants,
	getUniqueMeetupReviews,
	deleteUserMeetup,
	deleteUserMeetupCover,
	applyUserMeetup,
	getApplyUserMeetupStatus,
	getExtraApplyUserMeetupStatus
} from 'apis/meetup';

import { convertToTimestamp } from '../utils/utils';

const actionTypes = {
	SET_FILTER: 'SET_FILTER'
};

const initialState = {
	cityID: [],
	countryID: '',
	meetup: [],
	timePeriods: [],
	treatment: '',
	sorter: { name: '即將開始', ID: 'upcoming' },
	dateStart: '',
	dateEnd: '',
	searchTitle: ''
};

const reducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SET_FILTER:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const MeetupContext = createContext({});

export const MeetupProvider = (props) => {
	const [loading, setLoading] = useState(false);
	const [meetupData, setMeetupData] = useState([]);

	const [state, dispatch] = useReducer(reducer, initialState);
	const setFilter = (data) =>
		dispatch({
			type: actionTypes.SET_FILTER,
			payload: data
		});

	const getMeetupPlace = async (val) => {
		setLoading(true);
		try {
			const response = await getUserMeetupPlace(val);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const updateMeetup = async (body, meetupID) => {
		setLoading(true);
		try {
			const response = await updateUserMeetup(body, meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const applyMeetup = async (body, meetupID) => {
		setLoading(true);
		try {
			const response = await applyUserMeetup(body, meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getApplyMeetupStatus = async (meetupID) => {
		setLoading(true);
		try {
			const response = await getApplyUserMeetupStatus(meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getExtraApplyMeetupStatus = async () => {
		setLoading(true);
		try {
			const response = await getExtraApplyUserMeetupStatus();
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const createMeetup = async (body, placeID) => {
		setLoading(true);
		try {
			const response = await createUserMeetup(body, placeID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const deleteMeetup = async (meetupID) => {
		setLoading(true);
		try {
			const response = await deleteUserMeetup(meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const deleteMeetupCover = async (meetupID, files) => {
		setLoading(true);
		try {
			const response = await deleteUserMeetupCover(meetupID, files);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const createMeetupPublishLocation = async (body, meetupID) => {
		setLoading(true);
		try {
			const response = await createUserMeetupPublishLocation(body, meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const createMeetupCover = async (files, meetupID) => {
		setLoading(true);
		try {
			const response = await createUserMeetupCover(files, meetupID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmitData = (values) => {
		// console.log('values:', values)
		const submitData = new URLSearchParams();
		if (values) {
			const cities = values.cityID ? values.cityID : [];
			if (cities.length > 0) {
				submitData.append('cities', JSON.stringify(cities));
			}

			// categories
			const categoryList = [];
			if (values.meetup && values.meetup.length > 0) {
				values.meetup.map((item) => {
					categoryList.push(item.ID);
				});
				submitData.append('categories', JSON.stringify(categoryList));
			}
			// timeList
			values.timePeriods &&
				values.timePeriods.map((item) => {
					submitData.append('timeList', item.ID);
				});

			// string values
			if (values.sorter) {
				submitData.append('sort', values.sorter.ID);
			}
			if (values.dateStart) {
				submitData.append('startAt', convertToTimestamp(values.dateStart));
			}
			if (values.dateEnd) {
				submitData.append('endAt', convertToTimestamp(values.dateEnd));
			}

			const treatmentIDs = values.treatment
				? values.treatment.map((item) => item.ID)
				: [];
			// isTreated
			if (treatmentIDs.includes('isHostTreat')) {
				submitData.append('isTreated', true);
			}
			// isRewarding
			if (treatmentIDs.includes('isReward')) {
				submitData.append('isRewarding', true);
			}
			if (values.searchTitle) {
				submitData.append('title', values.searchTitle);
			}
		}
		return submitData;
	};

	const getMeetupListByCity = async (data, countryID) => {
		setLoading(true);
		try {
			const response = await getAllMeetupListByCity(data, countryID);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getCreateMeetupStatus = async () => {
		setLoading(true);
		try {
			const response = await getUserCreateMeetupStatus();
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getMeetupCover = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupCover(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getMeetupDetail = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupDetail(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getMeetupReviews = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupReviews(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getMeetupApplicants = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupApplicants(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getMeetupParticipants = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupParticipants(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const getMeetupComments = async (meetid) => {
		setLoading(true);
		try {
			const response = await getUniqueMeetupComments(meetid);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const createMeetupComment = async (meetid, message) => {
		setLoading(true);
		try {
			const response = await createUniqueMeetupComment(meetid, message);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const editMeetupComment = async (meetid, message) => {
		setLoading(true);
		try {
			return await editUniqueMeetupComment(meetid, message);
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};

	const deleteMeetupComment = async (commentId) => {
		setLoading(true);
		try {
			const response = await deleteUniqueMeetupComment(commentId);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	const hideMeetupComment = async (commentId) => {
		setLoading(true);
		try {
			const response = await hideUniqueMeetupComment(commentId);
			return response;
		} catch (e) {
			console.log('e: ', e);
		} finally {
			setLoading(false);
		}
	};
	return (
		<MeetupContext.Provider
			value={{
				createMeetupPublishLocation,
				createMeetupCover,
				createMeetup,
				getMeetupPlace,
				getMeetupListByCity,
				getCreateMeetupStatus,
				getMeetupCover,
				getMeetupComments,
				createMeetupComment,
				deleteMeetupComment,
				hideMeetupComment,
				updateMeetup,
				editMeetupComment,
				getMeetupDetail,
				getMeetupApplicants,
				getMeetupParticipants,
				getMeetupReviews,
				deleteMeetup,
				deleteMeetupCover,
				applyMeetup,
				getApplyMeetupStatus,
				getExtraApplyMeetupStatus,
				filterData: state,
				setFilter,
				handleSubmitData,
				meetupData,
				setMeetupData,
				loading
			}}>
			<>{props.children}</>
		</MeetupContext.Provider>
	);
};
