/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[types.CLEAR_PROFILE]: (state, action) => ({
			...initialState
		}),
		[types.SET_LOADING]: (state, action) => ({
			...state,
			loading: action.payload.loading
		}),
		[types.SET_MEMBER_ID]: (state, action) => ({
			...state,
			id: action.payload.id
		}),
		[`${types.FETCH_PROFILE}_FULFILLED`]: (state, action) => ({
			...state,
			data: action.payload.member,
			id: action.payload.id
		}),
		[`${types.FETCH_PROFILE_AVATAR}_FULFILLED`]: (state, action) => ({
			...state,
			avatar: action.payload.avatars
		}),
		[`${types.FETCH_FOLLOWING_STATUS}_FULFILLED`]: (state, action) => ({
			...state,
			followingStatus: action.payload.status
		}),
		[`${types.FETCH_RATING_SCORE}_FULFILLED`]: (state, action) => ({
			...state,
			rating: action.payload.rating
		}),
		[`${types.FETCH_RATING_SCORE}_REJECTED`]: (state, action) => ({
			...state,
			rating: action.payload.rating
		}),
		[`${types.FETCH_REVIEW}_FULFILLED`]: (state, action) => ({
			...state,
			reviews: action.payload.reviews
		}),
		[`${types.FETCH_FRIEND_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				friend: action.payload.friendAmount
			}
		}),
		[`${types.FETCH_FANS_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				fan: action.payload.fansAmount
			}
		}),
		[`${types.FETCH_FOLLOWING_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				following: action.payload.followingAmount
			}
		}),
		[`${types.FETCH_MEETUP_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				meetup: {
					amount: action.payload.amount,
					success: action.payload.success
				}
			}
		}),
		[`${types.FETCH_ATTEND_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				attend: action.payload.amount
			}
		}),
		[`${types.FETCH_APPLY_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				apply: {
					amount: action.payload.amount,
					success: action.payload.success
				}
			}
		}),
		[`${types.FETCH_FRIEND}_FULFILLED`]: (state, action) => ({
			...state,
			friends: action.payload.friends
		}),
		[`${types.FETCH_FANS}_FULFILLED`]: (state, action) => ({
			...state,
			fans: action.payload.fans
		}),
		[`${types.FETCH_FOLLOWING}_FULFILLED`]: (state, action) => ({
			...state,
			followings: action.payload.followings
		}),
		[`${types.FETCH_MEETUP}_FULFILLED`]: (state, action) => ({
			...state,
			meetups: action.payload.meetups
		})
	},
	initialState
);
