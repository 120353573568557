/** @format */

export const requestSelector = (state) => state.navbar.request;
export const tokenSelector = (state) => state.navbar.token;
export const statusSelector = (state) => state.navbar.status;
export const navbarDataSelector = (state) => state.navbar.data;
export const avatarSelector = (state) => state.navbar.avatar;
export const friendAmountSelector = (state) => state.navbar.amount.friend;
export const fanAmountSelector = (state) => state.navbar.amount.fan;
export const followingAmountSelector = (state) => state.navbar.amount.following;
export const meetupAmountSelector = (state) => state.navbar.amount.meetup;
export const attendAmountSelector = (state) => state.navbar.amount.attend;
export const applyAmountSelector = (state) => state.navbar.amount.apply;
export const forbiddenTextListSelector = (state) => state.navbar.forbiddenTextList;

