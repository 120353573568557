/** @format */

import { combineReducers } from 'redux';
import navbar from './navbar/reducer';
import dashboard from './dashboard/reducer';
import member from './member/reducer';
import meetup from './meetup/reducer';
import ui from './ui/reducer';
import post from './post/reducer';

const reducers = combineReducers({
	navbar,
	dashboard,
	member,
	meetup,
	ui,
	post
});

export default reducers;
