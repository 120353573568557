/** @format */

export const initialState = {
	useAppModal: false,
	shareModal: {
		copy: '',
		isOpen: false
	},
	extraMeetupModal: false,
	profileModifyModal: false,
	rightSidebar: false,
	alertModal: {
		isOpen: false,
		type: ''
	}
};
