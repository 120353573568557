/** @format */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// i18n.on("languageChanged", function(lng) {
//   // if the language we switched to is the default language we need to remove the /en from URL
//   if (lng === i18n.options.fallbackLng[0]) {
//     if (window.location.pathname.includes("/" + i18n.options.fallbackLng[0])) {
//       const newUrl = window.location.pathname.replace(
//         "/" + i18n.options.fallbackLng[0],
//         ""
//       );
//       window.location.replace(newUrl);
//     }
//   }
// });

const options = {
	load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
	// have a common namespace used around the full app
	ns: ['translations'],
	defaultNS: 'translations',
	saveMissing: true,
	debug: false,
	nonExplicitWhitelist: true,
	fallbackLng: 'zh-TW',
	caches: ['localStorage', 'cookie'],
	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ','
	},
	react: {
		wait: true,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default',
		useSuspense: false //
	},
	detection: {
		order: [
			'path',
			'querystring',
			'localStorage',
			'sessionStorage',
			'navigator',
			'htmlTag'
		],
		lookupFromPathIndex: 0,
		checkWhitelist: true,
		lookupLocalStorage: 'i18nextLng',
		lookupQuerystring: 'lang',
		lookupCookie: 'i18next',
		lookupLocalStorage: 'i18nextLng',
		lookupSessionStorage: 'i18nextLng',
		lookupFromPathIndex: 0,
		lookupFromSubdomainIndex: 0,
		lookupFromPathIndex: 0,
		checkWhitelist: true
	}
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
	i18n.use(XHR).use(initReactI18next).use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
	i18n.init(options);
}

export default i18n;
