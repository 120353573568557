/** @format */

const types = {
	ADD_LOADING_REQUEST: 'ADD_LOADING_REQUEST',
	REMOVE_LOADING_REQUEST: 'REMOVE_LOADING_REQUEST',
	SET_SELECTED_COUNTRY: 'SET_SELECTED_COUNTRY',
	SET_SELECTED_CITY: 'SET_SELECTED_CITY',
	SET_SELECTED_TITLE: 'SET_SELECTED_TITLE',
	FETCH_FILTER_KEYWORD: 'FETCH_FILTER_KEYWORD',
	FETCH_LANGUAGE: 'FETCH_LANGUAGE',
	FETCH_COUNTRIES: 'FETCH_COUNTRIES',
	FETCH_SELECTED_COUNTRY: 'FETCH_SELECTED_COUNTRY',
	FETCH_CITIES: 'FETCH_CITIES',
	FETCH_MEETUPS: 'FETCH_MEETUPS',
	RESET_FILTER: 'RESET_FILTER',
	SET_TITLE: 'SET_TITLE'
};

export default types;
