/** @format */

import { handleActions } from 'redux-actions';
import { initialState } from './state';
import types from './types';

export default handleActions(
	{
		[`${types.GET_TOKEN}`]: (state, action) => ({
			...state,
			token: action.payload.token
		}),
		[`${types.REMOVE_TOKEN}`]: (state, action) => ({
			...state,
			token: initialState.token
		}),
		[`${types.ADD_SYSTEM_REQUEST}`]: (state, action) => ({
			...state,
			request: action.payload.request
		}),
		[`${types.REMOVE_SYSTEM_REQUEST}`]: (state, action) => ({
			...state,
			request: action.payload.request
		}),
		[types.FETCH_LANGUAGE]: (state, action) => ({
			...state,
			filter: {
				...state.filter,
				language: action.payload.language
			}
		}),
		[types.FETCH_FORBBIDEN_TEXT_LIST]: (state, action) => ({
			...state,
			forbiddenTextList: action.payload.list
		}),
		[`${types.CHECK_CREATE_MEETUP_STATUS}_FULFILLED`]: (state, action) => ({
			...state,
			status: action.payload.status
		}),
		[`${types.FETCH_USER_MEETUP_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				meetup: {
					amount: action.payload.amount,
					success: action.payload.success
				}
			}
		}),
		[`${types.FETCH_USER_ATTEND_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				attend: action.payload.amount
			}
		}),
		[`${types.FETCH_USER_APPLY_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				apply: {
					amount: action.payload.amount,
					success: action.payload.success
				}
			}
		}),
		[`${types.FETCH_USER_FANS_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				fan: action.payload.fansAmount
			}
		}),
		[`${types.FETCH_USER_FOLLOWING_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				following: action.payload.followingAmount
			}
		}),
		[`${types.FETCH_USER_FRIEND_AMOUNT}_FULFILLED`]: (state, action) => ({
			...state,
			amount: {
				...state.amount,
				friend: action.payload.friendAmount
			}
		}),
		[`${types.FETCH_USER_PROFILE}_FULFILLED`]: (state, action) => ({
			...state,
			data: action.payload.member
		}),
		[`${types.FETCH_USER_PROFILE_AVATAR}_FULFILLED`]: (state, action) => ({
			...state,
			avatar: action.payload.avatars
		}),
		[`${types.CLEAR_USER_DATA}`]: (state, action) => ({
			...state,
			data: { ...initialState.data },
			avatar: initialState.avatar,
			amount: initialState.amount
		}),
		[`${types.RESET_CREATE_MEETUP_STATUS}`]: (state, action) => ({
			...state,
			status: { ...initialState.status }
		})
	},
	initialState
);
