/** @format */

import React, { useState, useEffect } from 'react';
import { createContext, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import {
	getRegisterStatusByToken,
	authorizeUser,
	authenticateUser,
	getNewRefreshToken,
	registerUser,
	getRecommendFollowList
} from 'apis/auth';
import i18n from '../i18n';
import { getAPILanguage, getLocalStorageLng } from 'utils/utils';

const baseUrl = `/${i18n.language}`.toLowerCase();

export const AuthContext = createContext({});
export const registrationStatusMap = {
	newUser: 0,
	unfinished: 1,
	registered: 2
};

export const AuthProvider = (props) => {
	const history = useHistory();
	const localizedLang = getLocalStorageLng();
	var finishTime;
	var timeoutID;
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);
	const [refreshStatus, setRefreshStatus] = useState(false);
	const [registerStatus, setRegisterStatus] = useState(null);
	const [text, setText] = useState(null);

	const [userInfo, setUserInfo] = useState({
		phoneNumber: '',
		phoneCode: '',
		email: ''
	});

	const windowGlobal = typeof window !== 'undefined' && window;
	const token = windowGlobal && windowGlobal.localStorage.getItem('token');

	const setToken = (token) => {
		if (!token) return;
		localStorage.setItem('token', token);
	};
	const setRefreshToken = (token) => {
		if (!token) return;
		localStorage.setItem('refreshToken', token);
	};
	const refreshToken = async () => {
		try {
			const response = await getNewRefreshToken();
			if (response.status == 200) {
				console.log(response, 'refresh token callback');
				setToken(response.data.accessToken);
				setRefreshToken(response.data.accessToken);
			} else {
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
			}
			return response;
		} catch (e) {
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			console.log('refreshToken: ', e);
			return e.response;
		}
	};

	const updateTokenTimer = () => {
		setRefreshStatus(true);
		const finishTime = localStorage.getItem('refreshTokenTimer');
		const timeLeft = finishTime - new Date();
		const finalResult = Math.max(timeLeft / 1000, 0).toFixed(0);
		setText(finalResult);
		if (finalResult == 0) {
			clearTimeout(updateTokenTimer);
			setRefreshStatus(false);
			setText(null);
			refreshToken();
			localStorage.removeItem('refreshTokenTimer');
			return;
		}
		timeoutID = window.setTimeout(updateTokenTimer, 100);
	};
	//nono

	const getToken = () => {
		const windowSetting = typeof window !== 'undefined' && window;

		return (
			windowSetting &&
			windowSetting.localStorage &&
			localStorage.getItem('token')
		);
	};

	const getRefreshToken = () => {
		return (localStorage && localStorage.getItem('refreshToken')) || '';
	};

	const register = async (phoneCode, phoneNumber, data) => {
		setLoading(true);

		try {
			const { email, aliasID: referenceCode } = data;
			const response = await registerUser(
				phoneCode,
				phoneNumber,
				email,
				referenceCode
			);
			setUserInfo({
				phoneCode,
				phoneNumber,
				email,
				referenceCode
			});
			return response;
		} catch (e) {
			console.log('register: ', e);
		} finally {
			setLoading(false);
		}
	};

	const authenticate = async (phoneCode, phoneNumber) => {
		setLoading(true);

		try {
			const response = await authenticateUser(phoneCode, phoneNumber);
			setUserInfo({
				...userInfo,
				phoneNumber,
				phoneCode
			});

			return response;
		} catch (e) {
			console.log('authenticate: ', e);
		} finally {
			setLoading(false);
		}
	};

	const authorize = async (phoneCode, phoneNumber, verifyCode) => {
		setLoading(true);
		try {
			const response = await authorizeUser(phoneCode, phoneNumber, verifyCode);
			if (response.status == 200) {
				const { accessToken, refreshToken } = response.data;
				setToken(accessToken);
				setRefreshToken(refreshToken);
				const { exp } = jwtDecode(getToken());
				localStorage.setItem('refreshTokenTimer', (exp - 5 * 60) * 1000);
				if (timeoutID != undefined) {
					window.clearTimeout(timeoutID);
				}
				updateTokenTimer();
			} else {
				return response;
			}
		} catch (e) {
			console.log('authorize: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getRegisterStatus = async () => {
		setLoading(true);

		try {
			const response = await getRegisterStatusByToken();
			setRegisterStatus(response.data.status);
			// New registration
			if (response.data.status === registrationStatusMap.newUser) {
				setIsLoggedIn(true);
				history.push(`/${localizedLang}/signup`);
			}

			// Unfinished registration
			if (response.data.status === registrationStatusMap.unfinished) {
				setIsLoggedIn(true);
			}

			// Logged in
			if (response.data.status === registrationStatusMap.registered) {
				setIsLoggedIn(true);
			}
			if (response.status == 401) {
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
			}
			return response;
		} catch (e) {
			console.log('getRegisterStatus: ', e);
		} finally {
			setLoading(false);
		}
	};

	const getUserRecommendFollowList = async () => {
		try {
			const response = await getRecommendFollowList();
			return response;
		} catch (e) {
			return e.response;
		}
	};

	const logOut = async () => {
		setIsLoggedIn(false);
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		history.push(`${baseUrl}/login`);
	};

	useEffect(() => {
		if (localStorage.getItem('refreshTokenTimer')) {
			updateTokenTimer();
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{
				setLoading,
				loading,
				registerStatus,
				isLoggedIn,
				userInfo,
				getToken,
				getRefreshToken,
				authorize,
				authenticate,
				register,
				getRegisterStatus,
				logOut,
				setToken,
				setIsLoggedIn,
				getUserRecommendFollowList
			}}>
			<>{props.children}</>
		</AuthContext.Provider>
	);
};
