/** @format */

const types = {
	TOGGLE_USE_APP_MODAL: 'TOGGLE_USE_APP_MODAL',
	TOGGLE_EXTRA_MEETUP_MODAL: 'TOGGLE_EXTRA_MEETUP_MODAL',
	TOGGLE_PROFILE_MODIFY_MODAL: 'TOGGLE_PROFILE_MODIFY_MODAL',
	TOGGLE_RIGHT_SIDEBAR_MODAL: 'TOGGLE_RIGHT_SIDEBAR_MODAL',
	TOGGLE_ALERT_MODAL: 'TOGGLE_ALERT_MODAL',
	TOGGLE_SHARE_MODAL: 'TOGGLE_SHARE_MODAL'
};

export default types;
