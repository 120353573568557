/** @format */

import { useDispatch, useSelector } from 'react-redux';
import {
	requestSelector,
	tokenSelector,
	statusSelector,
	navbarDataSelector,
	avatarSelector,
	friendAmountSelector,
	fanAmountSelector,
	followingAmountSelector,
	attendAmountSelector,
	applyAmountSelector,
	meetupAmountSelector,
	forbiddenTextListSelector
} from './selector';
import {
	removeToken,
	getToken,
	fetchForbiddenTextList,
	checkCreateMeetupStatus,
	resetCreateMeetupStatus,
	fetchProfile,
	fetchProfileAvatar,
	fetchMeetupAmount,
	fetchAttendAmount,
	fetchApplyAmount,
	fetchFriendAmount,
	fetchFansAmount,
	fetchFollowingAmount,
	clearUserData
} from './action';
import { useCallback, useEffect } from 'react';
import { getLocalStorageToken } from '../../utils/utils';

export const useSystem = () => {
	const dispatch = useDispatch();
	const request = useSelector(requestSelector);
	const forbiddenTextList = useSelector(forbiddenTextListSelector);

	const boundFetchForbiddenTextList = useCallback(
		(list) => {
			dispatch(fetchForbiddenTextList(list));
		},
		[dispatch]
	);

	return {
		pending: request > 0,
		forbiddenTextList,
		boundFetchForbiddenTextList
	};
};

export const useNavbar = () => {
	const dispatch = useDispatch();
	const { aud } = useSelector(tokenSelector);
	const status = useSelector(statusSelector);
	const navbarData = useSelector(navbarDataSelector);
	const avatars = useSelector(avatarSelector);
	const friendAmount = useSelector(friendAmountSelector);
	const fanAmount = useSelector(fanAmountSelector);
	const followingAmount = useSelector(followingAmountSelector);
	const attendAmount = useSelector(attendAmountSelector);
	const applyAmount = useSelector(applyAmountSelector);
	const meetupAmount = useSelector(meetupAmountSelector);

	const boundRemoveToken = useCallback(() => {
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		dispatch(removeToken());
	}, [dispatch]);

	const boundGetToken = useCallback(() => {
		dispatch(getToken());
	}, [dispatch]);

	const boundCheckCreateMeetupStatus = useCallback(() => {
		dispatch(checkCreateMeetupStatus());
	}, [dispatch]);

	const boundResetCreateMeetupStatus = useCallback(() => {
		dispatch(resetCreateMeetupStatus());
	}, [dispatch]);

	const boundFetchUserData = useCallback(() => {
		dispatch(fetchProfile());
		dispatch(fetchProfileAvatar());
	}, [dispatch]);

	const boundFetchUserDetail = useCallback(() => {
		dispatch(fetchMeetupAmount());
		dispatch(fetchAttendAmount());
		dispatch(fetchApplyAmount());
		dispatch(fetchFriendAmount());
		dispatch(fetchFansAmount());
		dispatch(fetchFollowingAmount());
	}, [dispatch]);

	const boundClearUserData = useCallback(() => {
		dispatch(clearUserData());
	}, [dispatch]);

	useEffect(() => {
		boundGetToken();
	}, [getLocalStorageToken()]);

	return {
		aud,
		status,
		navbarData,
		avatars,
		friendAmount,
		fanAmount,
		followingAmount,
		attendAmount,
		applyAmount,
		meetupAmount,
		boundCheckCreateMeetupStatus,
		boundResetCreateMeetupStatus,
		boundRemoveToken,
		boundGetToken,
		boundFetchUserData,
		boundFetchUserDetail,
		boundClearUserData
	};
};
